<template>
  <div class="section sectionLoading">
    <div class="container-fluid">
      <Loading />
      <p class="text-center mt-2 small text-dark">{{ text }}</p>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";
export default {
  components: {
    Loading,
  },
  props: {
    text: {
      type: "",
      required: false,
    },
  },
};
</script>