<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">All Blog</h2>
      </div>

      <div class="col-md-6">
        <div class="float-right d-flex">
          <form @submit.prevent="searchRelease">
            <vs-input
              class="app-search"
              icon="search"
              placeholder=""
              v-model="searchData"
            />
          </form>

          <router-link to="/add-blog" class="btn btn-primary btn-site ml-2"
            >Add Blog</router-link
          >
        </div>
      </div>
    </div>
    <div>
      <SectionLoading v-if="loading" text="Blog loading..." />
      <div v-else>
        <div
          v-if="contents && contents.data && contents.data.length == 0"
          class="text-center my-5"
        >
          <p class="text-dark mt-5 pt-5">No Blog yet.</p>
          <router-link to="/add-blog" class="btn btn-primary btn-site mt-2"
            >Create New Blog</router-link
          >
        </div>
        <div v-else>
          <div class="row">
            <div
              class="col-md-3"
              v-for="(item, index) in contents.data"
              :key="index"
            >
              <div
                @click="$router.push(`/blog/${item.slug}`)"
                class="mb-5 cursor-pointer"
              >
                <div
                  class="blog-img"
                  :style="`background-image: url(${item.avatar})`"
                ></div>
                <p class="text-primary title font-weight-bold mb-0 mt-3">
                  {{ item.title | capitalize }}
                </p>
                <p class="small text-dark">{{ item.summary }}</p>
              </div>
            </div>
          </div>

          <vs-pagination
            v-if="contents"
            class="mt-4"
            :total="Math.ceil(contents.total / table_options.per_page)"
            v-model="table_options.current_page"
          ></vs-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import SectionLoading from "../../../components/SectionLoading";
export default {
  name: "Home",
  components: {
    PlusIcon,
    SectionLoading,
  },
  data() {
    return {
      loading: false,
      searchData: "",
      contents: [],
      list: "tile",
      listStyles: [
        { text: "Tile View", value: "tile" },
        { text: "List View", value: "list" },
      ],

      table_options: {
        current_page: 1,
        per_page: 20,
      },
    };
  },
  watch: {
    "table_options.current_page": function () {
      this.getContents(true);
    },
  },
  mounted() {
    this.getBl();
  },
  methods: {
    getBl() {
      this.getContents(false);
    },

    searchRelease() {
      this.loading = true;
      if (this.searchData.length == "") {
        this.getContents(false);
        return;
      }

      let fetch = {
        path: `/admin/blog`,
        data: {
          search: this.searchData,
        },
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.contents.data = resp.data.data;

          if (this.list == "list") {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Blog",
              text: "Unable to get Blog data",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },

    getContents(divLoad) {
      this.loading = true;
      let fetch = {
        path: `/admin/blog?page=${this.table_options.current_page}&page_size=${this.table_options.per_page}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contents = resp.data.data;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Artisits",
              text: "Unable to get Artists data",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    playAudio(source) {
      this.$store.commit("audioPlay", {
        status: true,
        source,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-img {
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 100%;
  border-radius: 5px;
}
</style>
